import { Parallax, Background } from "react-parallax";
import "./App.css";

const Header = () => (
  // <Parallax
  //   blur={0}
  //   bgImage="./sc-ig.png"
  //   bgImageAlt="saint cyanide logo"
  //   strength={200}
  // >
  //   {/* <div className="header-nav-wrapper">
  //     <a href="#" id="music">
  //       <FontAwesomeIcon icon={faMusic} />
  //     </a>
  //     <a href="#" id="info">
  //       <FontAwesomeIcon icon={faInfoCircle} />
  //     </a>
  //     <a href="#" id="contact">
  //       <FontAwesomeIcon icon={faEnvelope} />
  //     </a>
  //   </div> */}
  //   <h1 className="header-name">
  //     broke<br></br>
  //     <span className="header-second">bldgs</span>
  //   </h1>
  // </Parallax>
  <div className="bg">
  </div>
);

export default Header;
