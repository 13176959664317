import "./App.css";

const Info = () => (
  <>
    <div className="info-container" id="info">
      <h1>info</h1>
      <img src="./sc-photo-bw.jpg" alt="image of the people in broke bldgs" />
      <div className="info-copy">
        <p>
          <strong>Saint Cyanide</strong> makes designer noise. Their music combines dark synth
          basslines with post-punk guitars and dance floor drums - a reflection
          of their various influences and inspirations. The result is familiar
          yet original; a party and a gut punch. They consist of Philip Schorn
          (synth, percussion), Rick Mitchell (guitar, synth) and Steven Haslam
          (vocals). They’ve played in dozens of bands over the years - together
          and separately - and blah blah blah, etc., etc., etc. Proudly from
          Philadelphia.
        </p>
      </div>
    </div>
  </>
);

export default Info;
